import { useEffect, useState } from 'react';
import './App.css';
import { read, utils } from 'xlsx';
import Scraped from './Scraped';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

function App() {
  const counter = useSelector((state) => state.counter.value);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totaldata, setTotalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sheetdata, setSheetdata] = useState();
  const [sheetnames, setSheetnames] = useState();
  const [tabedata, setTabledata] = useState([]);
  const [tableheader, setTableheader] = useState([]);
  const [showtable, setShowtable] = useState(false);

  const [percentage, setPercentage] = useState(0);

  // Prevent accidental refresh
  function handleBeforeUnload(e) {
    e.preventDefault();
    e.returnValue = '';
  }
  window.addEventListener('beforeunload', handleBeforeUnload);
  

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const sheets = e.target.result;
      const workbook = read(sheets, { type: 'binary' });
      const sheetNames = workbook.SheetNames;
      const data = {};
      sheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        data[sheetName] = utils.sheet_to_json(sheet);
      });
      setSheetnames(sheetNames);
      setSheetdata(data);
      setTabledata();
    };
    reader.readAsBinaryString(file);
  };

  const parseSheet = (data) => {
    // console.log(Object.values(data[0]))
    const p = window.prompt('Enter Percentage Value');
    if (isNaN(p)) return;
    setPercentage(parseFloat(p));
    setTableheader(Object.values(data[0]));
    // const header = data.splice(0,1)
    const pages = Math.ceil(data?.length / 10);
    setTotal(pages);
    setPage(1);
    setTabledata(data.slice(0, 10));
    setTotalData(data);
    setShowtable(true);
  };

  useEffect(() => {
    setLoading(true);
    let start = tabedata.length - 1;
    setTabledata((p) => {
      return [...p, ...totaldata.slice(start, start + 10)];
    });
  }, [page]);
  const handleScroll = () => {
    console.log(
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight
    );
    console.log(
      window.innerHeight + document.documentElement.scrollTop,
      document.documentElement.offsetHeight
    );
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      console.log('increase');
      setPage((prevPage) => {
        return prevPage + 1;
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div className="App">
      {!showtable && <input onChange={handleChange} type="file" />}
      {showtable ? (
        <>
          <button
            onClick={(e) => {
              setShowtable(false);
            }}
            className="btn"
          >
            Back to home
          </button>
          <table cellSpacing="0">
            <thead>
              <tr>
                {tableheader.map((item, i) => (
                  <th
                    key={i}
                    style={{ width: `${i == 2 ? '260px' : 'fit-content'}` }}
                  >
                    {item.includes('Pallet') ? 'PID #' : item}
                  </th>
                ))}
                <th>Retail</th>
                <th>Image</th>
                <th>Difference</th>
                <th>Price</th>


                <th>Action</th>
              </tr>
              <tr>
                <td colSpan={12} style={{ padding: '10px' }}>
                  {Object.keys(tabedata[0])[0]}
                </td>
              </tr>
              {tabedata.map((row, i) => {
                // console.log(row)
                console.log(Object.keys(row)[0]);
                let keys = Object.keys(tabedata[0]);
                let sku = Object.values(row)[0];
                if (sku?.toString()?.trim() === 'SKU') return <></>;

                return (
                  <tr key={i}>
                    {keys.map((ky, i) => (
                      <td>
                        {i == 0 || i == 3 ? (
                          <a
                            href={'https://homedepot.com/s/' + row[ky]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row[ky]}
                          </a>
                        ) : (
                          row[ky]
                        )}
                      </td>
                    ))}
                    <Scraped
                      sku={sku}
                      product={row}
                      keys={keys}
                      percentage={percentage}
                    />
                  </tr>
                );
              })}
            </thead>
          </table>
          <div className="pagination">
            <button
              onClick={(e) => {
                setShowtable(false);
              }}
              className="btn"
            >
              Back to home
            </button>
            <span>
              {page > total ? total : page} of {total} pages{' '}
            </span>

            {page < total && (
              <button onClick={() => setPage((p) => p + 1)} className="btn">
                Load Next
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="tiles">
          {sheetdata &&
            sheetnames &&
            sheetnames.map((name, i) => (
              <div
                className="tile"
                key={i}
                onClick={() => parseSheet(sheetdata[name])}
              >
                <div className="sheet">
                  <span>{name}</span>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default App;
