import axios from 'axios';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from './countSlice';

const Scraped = ({ sku, product, keys, percentage }) => {
  const counter = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();
  const [img, setImag] = useState('Scrapping...');
  const [price, setPrice] = useState('Scrapping...');
  const [diff, setDiff] = useState(0);
  const [status, setStatus] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [editPrice, setEditPrice] = useState();
  const [editImage, setEditImage] = useState();
  

  const values = keys.map((ky) => product[ky]);
  const row = {
    sku: values[0],
    scan_lp: values[1],
    description: values[2],
    model: values[3],
    palet_id: values[4] || '',
    quantity: values[5],
    wholesale: values[6],
  };
  //  console.log(row)
  const handleEdit = () => {
    setEditMode(!editMode);
  };
  const handleCancel = () => {
    setEditMode(false);
  };
  const handleSave = () => {
    row['price'] = editPrice;
    row['image'] = editImage;

    // axios.post("http://localhost/felixramirez800/second/edit.php",row)
    axios.post("http://www.manifestz.com/edit.php",row)
    .then((res) => {
      console.log(res.data);
      setStatus(true);
      if (!res.data?.image) {
        setImag('edit Not Found');
      } else {
        setImag(res.data?.image);
      }

      if (!res.data?.price) {
        setPrice('Edit Not Found');
      } else {
        setPrice(res.data?.price);
        try {
          let dif;
          if (isNaN(parseFloat(row.wholesale))) {
            dif = parseFloat(res.data.price) - parseFloat(row.quantity);
          } else {
            dif =
              parseFloat(res.data.price) -
              parseFloat(row.wholesale) / parseFloat(row.quantity);
          }
          setDiff(dif.toFixed(2));
        } catch (err) {
          console.log(err);
        }
      }
    })
    .catch(() => {
      setPrice('');
      setImag('');
    });
    
    setEditMode(false);
  };

  const getData = (count) => {
    if (status) return;
    if (count > 20) {
      setDiff('');
      setImag('');
      setPrice('Not found');
    }
    dispatch(increment());
    // post('http://localhost/felixramirez800/second/search.php?id=' + sku, row)
    // axios.post('http://www.manifestz.com/search.php?id=' + sku, row)
    axios
      .post('http://www.manifestz.com/search.php?id=' + sku, row)
      .then((res) => {
        console.log(res.data);
        setStatus(true);
        dispatch(decrement());
        if (!res.data?.image) {
          setImag('Not Found');
        } else {
          setImag(res.data?.image);
        }

        if (!res.data?.price) {
          setPrice('Not Found');
        } else {
          setPrice(res.data?.price);
          try {
            let dif;
            if (isNaN(parseFloat(row.wholesale))) {
              dif = parseFloat(res.data.price) - parseFloat(row.quantity);
            } else {
              dif =
                parseFloat(res.data.price) -
                parseFloat(row.wholesale) / parseFloat(row.quantity);
            }
            setDiff(dif.toFixed(2));
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch(() => {
        dispatch(decrement());
        setPrice('Retrying...');
        setImag('');
        setTimeout(() => {
          getData(count + 1);
        }, Math.random() * 3000);
      });
  };

  useLayoutEffect(() => {
    getData(0);
  }, []);

  return (
    <>
      <td>{ editMode ? <input type="text" id="price" name="price" className='input' onChange={e => setEditPrice(e.target.value)}/> : price}</td>
      <td>{ editMode ? <input type="text" id="image" name="image" className='input' onChange={e => setEditImage(e.target.value)}/>
          :
          <img
            src={img}
            alt={img}
            style={{
              with: '200px',
              height: '120px',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        }
      </td>
      <td>
        {diff > 35 ? <strong style={{ color: 'red' }}>{diff}</strong> : diff}
      </td>
      <td>{((percentage / 100) * parseFloat(values[6])).toFixed(2)}</td>
      <td>
        {editMode ? (
          <>
            <div className='d_flex'>
              <button onClick={handleSave} className='btn_save'>Save</button>
              <button onClick={handleCancel} className='btn_edit'>Cancel</button>
            </div>
          </>
        ) : (
          <button onClick={handleEdit} className='btn_edit'>Edit</button>
        )}
      </td>
    </>
  );
};

export default Scraped;
